.ta-full-list, .sfpreview__table {
     .table-wrapper {
          overflow-x: unset;
          position: relative;

          .table-banner {
               height: 42px;
               color: white;
               display: flex;

               .left {
                    border-top-left-radius: 12px;
                    padding: 0.5rem 0.75rem;
                    min-width: 250px;
                    background-color: #18222b;
                    position: relative;

                    &::after {
                         content: '';
                         display: block;
                         position: absolute;
                         top: 0;
                         right: 0;
                         height: 100%;
                         border-top: 19px solid #73818f;
                         border-bottom: 18px solid #73818f;
                         border-left: 10px solid #18222b;
                    }
               }

               .right {
                    border-top-right-radius: 12px;
                    padding: 0.5rem 0.75rem;
                    background-color: #73818f;
                    flex: 1;
               }
          }

          .table-spinner td {
               border: none;
          }

          .full-list-table {
               display: block;
               overflow-x: auto;
               overflow-y: auto;
               white-space: nowrap;
               margin-bottom: 0;

               td {
                    vertical-align: middle;
                    width: 100px;
                    height: 42px;
               }

               tr.header-row > td:first-child,
               tr.data-row > td:first-child {
                    position: sticky;
                    left: 0;
                    z-index: 2;

                    &::before {
                         content: ' ';
                         background: #e4e7ea;
                         width: 1px;
                         height: 100%;
                         position: absolute;
                         left: -1px;
                         top: 0px;
                    }

                    &::after {
                         content: ' ';
                         background: #e4e7ea;
                         width: 1px;
                         height: 100%;
                         position: absolute;
                         right: 0;
                         top: 0px;
                    }
               }

               tbody {
                    position: relative;
               }
          }

          .header-row,
          .data-row {
               td {
                    padding: 6px 12px;
                    border: 1px solid #e4e7ea;

                    &.is-temp {
                         padding: 0;
                    }
               }

               .filter-bo {
                    position: relative;
                    .btn-remove-row{
                         position: absolute;
                         right: 30px;
                         top: 50%;
                         transform: translateY(-50%);
                         cursor: pointer;
                         visibility: hidden;
                    }

                    select {
                         option:first-of-type{
                              display: none;
                         }
                    }

                    &:hover {
                         .btn-remove-row {
                              visibility: visible;

                              &.icon-close-hidden {
                                   display: none !important;
                              }
                         }
                    }
               }
          }

          .header-row {
               background-color: #f0f3f5;

               td {
                    background-color: #d2dbe0;
                    font-weight: 500;
               }

               .tooltip-wrapper {
                    position: relative;

                    > span svg path {
                         fill: #293742 !important;
                    }
               }
          }

          .data-row {
               .value-input-wrap {
                    position: relative;

                    .search-box-domain {
                         position: absolute;
                         top: 0;
                         left: 0;
                    }
               }

               .select-wrapper {
                    .custom-select__menu-list {
                         &::-webkit-scrollbar {
                              width: 3px;
                              height: 3px;
                         }

                         &::-webkit-scrollbar-track {
                              width: 3px;
                              background-color: #e2e6e9;
                         }

                         &::-webkit-scrollbar-thumb {
                              background: #73818f;
                              border: none;
                              width: 3px;
                         }
                    }
               }

               .no-results {
                    border-right: none;
               }

               .value-domain {
                    position: relative;

                    .not-selected {
                         input[class*='form-control'] {
                              color: #7f8c9a;
                              font-style: italic;
                         }
                    }

                    .btn-remove-row {
                         position: absolute;
                         border: none;
                         height: auto;
                         right: 33px;
                         visibility: hidden;
                         vertical-align: middle;
                         top: 50%;
                         transform: translateY(-50%);
                         z-index: 2;

                         i {
                              font-size: 20px;
                              cursor: pointer;
                              margin-top: 5px;
                         }
                    }

                    &:hover {
                         .btn-remove-row {
                              visibility: visible;

                              &.icon-close-hidden {
                                   display: none !important;
                              }
                         }
                    }
               }

               .value-profile {
                    text-align: center;
                    text-decoration: underline;
                    cursor: pointer;

                    &:hover {
                         text-decoration: none;
                    }
               }

               .value-bo {
                    input[type='checkbox'] {
                         margin: 0;
                         cursor: pointer;
                         opacity: 0;
                         width: 1rem;
                         height: 1rem;
                    }

                    label {
                         position: relative;
                         display: block;
                         width: 1rem;
                         height: 1rem;
                         cursor: pointer;
                         margin: 0;

                         &::before {
                              position: absolute;
                              top: 50%;
                              left: 50%;
                              transform: translate(-50%, -50%);
                              display: block;
                              width: 1rem;
                              height: 1rem;
                              pointer-events: none;
                              content: '';
                              color: #fff;
                              background-color: #fff;
                              border: #adb5bd solid 1px;
                              border-radius: 0.25rem;
                              cursor: pointer;
                         }

                         &::after {
                              position: absolute;
                              top: 50%;
                              left: 50%;
                              transform: translate(-50%, -50%);
                              display: block;
                              width: 1rem;
                              height: 1rem;
                              content: '';
                              background: no-repeat 50% / 50% 50%;
                         }
                    }

                    &.checked {
                         label {
                              &::before {
                                   background-color: #20a8d8;
                                   border-color: #20a8d8;
                              }

                              &::after {
                                   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
                              }
                         }

                         &.disabled {
                              label {
                                   &::before {
                                        background-color: #c2c2c2;
                                        border-color: #c2c2c2;
                                   }
                              }
                         }
                    }

                    &.disabled {
                         label {
                              &::before {
                                   cursor: default;
                              }
                              &::after {
                                   cursor: default;
                              }
                         }
                    }
               }

               .value-groups,
               .value-service-tag {
                    white-space: pre !important;
                    overflow: hidden;
                    border-left: 0;
                    font-size: 13px;

                    .base-tags {
                         display: flex;
                         flex-wrap: nowrap;
                         align-items: center;
                         justify-content: flex-start;
                         width: 230px;
                         overflow-x: scroll;

                         &::-webkit-scrollbar {
                              height: 0px;
                              width: 0px;
                              background: transparent;
                              /* make scrollbar transparent */
                         }
                    }

                    &.none {
                         padding-left: 17px;
                    }
               }
          }

          .float-select-search {
               position: absolute;
               z-index: 3;
               padding: 2px;
               height: 40px;

               input.stretch-container {
                    border-radius: unset;
                    border: none !important;
               }

               &.not-selected {
                    .select-search-input-wrapper .stretch-container.form-control {
                         color: #7f8c9a;
                         font-style: italic;
                    }
               }

               &.editing {
                    z-index: 10;
               }

               .select-search-input-wrapper {
                    .stretch-container.form-control {
                         border: none !important;
                         padding: 6px 12px;
                         color: #2f353a;

                         &:active {
                              border: 1px solid #e2e6e9;
                         }
                    }

                    .btn-remove-row {
                         position: absolute;
                         border: none;
                         height: auto;
                         right: 33px;
                         visibility: hidden;
                         vertical-align: middle;
                         top: 50%;
                         transform: translateY(-50%);
                         z-index: 2;

                         i {
                              font-size: 20px;
                              cursor: pointer;
                              margin-top: 5px;
                         }
                    }

                    &:hover {
                         .btn-remove-row {
                              visibility: visible;

                              &.icon-close-hidden {
                                   display: none !important;
                              }
                         }
                    }
               }

               .insert-variable-dropdown li.no-options {
                    color: #7f8c9a;
                    font-size: 14px;

                    &:hover {
                         background: unset;
                         cursor: default;
                    }

                    .option-add-new {
                         .btn-add-new {
                              margin-left: auto;
                              margin-right: auto;
                              padding-left: 0;
                              display: flex;
                              align-items: center;
                              grid-gap: 5px;
                              gap: 5px;

                              :hover {
                                   cursor: pointer;
                              }
                         }

                         .add-new-description {
                              margin: 0;
                              font-size: 13px;
                              text-wrap: balance;
                              word-break: break-word;
                         }
                    }

                    p.text{
                         font-size: 14px;
                         word-break: break-word;
                         line-height: 19px;
                    }
               }
          }
     }

     .ta-table-bottom {
          padding: 0;
          margin-bottom: 15px;

          .page-item.active .page-link {
               z-index: 0;

               &:hover{
                    color: white;
               }
          }

          .row {
               margin: 0;
          }

          ul.pagination {
               margin: 0;
          }
     }
     
     .row-rule {
          border-radius: 12px;
          background-color: #fff;

          .option-item {
               position: relative;

               ::before {
                    background-color: #b5dce9;
                    content: '';
                    display: block;
                    height: 20px;
                    left: 0;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
               }

               ::after {
                    background-color: #b5dce9;
                    content: '';
                    display: block;
                    height: 20px;
                    right: 0;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
               }
          }
     }

     &.server-side-trigger {
          tr.header-row > td:first-child,
          tr.data-row > td:first-child {
               position: relative !important;
          }
     }
}

.ta-find-account {
     .find-account-condition {
          position: relative;
          margin-bottom: 20px;
          width: 160px;

          .btn-add-condition {
               border: 1px solid !important;
               padding: 5px 10px;

               &:active{
                    color: #000;
               }
          }

          .condition-content {
               background-color: #fff;
               border: 1px solid #ddd;
               border-radius: 8px;
               box-shadow: 0 2px 4px #0000001a;
               position: absolute;
               z-index: 999;
               top: 50px;
               left: 0;
               padding: 10px 5px;
          }

          .filter-report {
               .stretch-container.select-multiple-variable-field {
                    height: auto;
               }
          }

          .filter-report-action {
               label {
                    font-size: 13px;
                    margin-bottom: 0;
                    text-decoration: underline;
                    text-transform: lowercase;
                    color: #000 !important;
                    font-weight: 400;
                    cursor: pointer;
               }

               svg {
                    margin: 0 0 0 5px;
                    cursor: pointer;
               }
          }
     }

     .report-table {
          .k-grid {
               border-top-left-radius: 0;
               border-top-right-radius: 0px;
               border-bottom-left-radius: 12px;
               border-bottom-right-radius: 12px;
          }

          .k-grid-header-wrap {
               border-top-left-radius: 0;
               border-top-right-radius: 0px;
          }

          .k-grid-footer-wrap {
               border-bottom-left-radius: 12px;
               border-bottom-right-radius: 12px;
          }

          .k-virtual-content::-webkit-scrollbar {
               width: 5px;
               height: 7px;
               position: relative;
               top: 10px;
          }

          .k-grid-draggable-header,
          .k-grid-footer {
               padding-right: 0;
          }

          .k-virtual-content::-webkit-scrollbar-track {
               background-color: rgb(226, 225, 225);
               border-bottom-left-radius: 9px;
               border-bottom-right-radius: 9px;
          }

          .k-virtual-content::-webkit-scrollbar-thumb {
               background-color: #bdbcbc;
               // border-bottom-left-radius: 9px;
               // border-bottom-right-radius: 9px;
               border-radius: 15px;
          }

          .k-table-thead {
               background: #8d9ba8;
          }

          .k-grid .k-grid-header {
               padding-right: 0;
          }
     }

     .btn-add {
          position: relative;

          .btn-add-row {
               position: absolute;
               top: 50%;
               left: 56%;
               transform: translate(-50%, -50%);
          }

          &.check-box {
               display: flex;
               align-items: center;
               justify-content: center;
               input[type='checkbox'] {
                    margin: 0;
                    cursor: pointer;
                    opacity: 0;
                    width: 1rem;
                    height: 1rem;
               }

               label {
                    position: relative;
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    cursor: pointer;
                    margin: 0;

                    &::before {
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         transform: translate(-50%, -50%);
                         display: block;
                         width: 1rem;
                         height: 1rem;
                         pointer-events: none;
                         content: '';
                         color: #fff;
                         background-color: #fff;
                         border: #adb5bd solid 1px;
                         border-radius: 0.25rem;
                         cursor: pointer;
                    }

                    &::after {
                         position: absolute;
                         top: 50%;
                         left: 50%;
                         transform: translate(-50%, -50%);
                         display: block;
                         width: 1rem;
                         height: 1rem;
                         content: '';
                         background: no-repeat 50% / 50% 50%;
                    }
               }

               &.checked {
                    label {
                         &::before {
                              background-color: #20a8d8;
                              border-color: #20a8d8;
                         }

                         &::after {
                              background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
                         }
                    }

                    &.disabled {
                         label {
                              &::before {
                                   background-color: #c2c2c2;
                                   border-color: #c2c2c2;
                              }
                         }
                    }
               }

               &.disabled {
                    label {
                         &::before {
                              cursor: default;
                         }
                         &::after {
                              cursor: default;
                         }
                    }
               }
          }
     }

     .view-detail {
          text-decoration: underline;
          cursor: pointer;

          &:hover {
               text-decoration: none;
          }

          &.list-tags {
               text-decoration: none !important;
               overflow-x: auto;

               &::-webkit-scrollbar {
                    display: none;
               }

               .no-click {
                    cursor: default !important;
               }
          }

          .tags-wrapper {
               display: flex;
               flex-wrap: nowrap;
               align-items: center;
               justify-content: flex-start;

               .tag {
                    cursor: pointer;
                    border-radius: 4px;
                    border: 1px solid rgb(195, 218, 254);
                    background-color: rgb(235, 244, 255);
                    padding: 2px 4px;
                    line-height: 15px;
                    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
                    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
                    white-space: nowrap;

                    + .tag {
                         margin-left: 4px;
                    }

                    &:not(.no-click):hover {
                         color: #fff;
                         border: 1px solid rgb(67, 65, 144);
                         background-color: rgb(67, 65, 144);
                    }
               }
          }
     }
}

.ta-edit-groups {
     .row-rule {
          border-radius: 12px;
          background-color: #fff;
          padding: 0 15px;

          .option-item {
               padding: 15px 14px;
               position: relative;

               ::before {
                    background-color: #b5dce9;
                    content: '';
                    display: block;
                    height: 20px;
                    left: 0;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
               }

               ::after {
                    background-color: #b5dce9;
                    content: '';
                    display: block;
                    height: 20px;
                    right: 0;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 1px;
               }
          }
     }

     .info-title {
          padding: 4px 10px 4px 0;
          max-width: 1100px;

          h4 {
               margin: 0;
               font-size: 15px;
          }
     }
}

.thin-scrollbar {
     &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          border-radius: 12px;
     }

     &::-webkit-scrollbar-track {
          width: 3px;
          background-color: #e2e6e9;
          border-radius: 2px;
     }

     &::-webkit-scrollbar-thumb {
          background: #73818f;
          border-radius: 12px;
          border: none;
          width: 3px;
     }
}
