.triggers-rules {
     z-index: 1;

     .list-rules {
          .accordion-item {
               .collapse {
                    overflow: inherit;
               }
          }
     }

     .icon-trigger {
          width: 16px;
          height: 16px;

          &.icon-action {
               width: 20px;
               height: 20px;
          }
     }
}

.rule-detail {
     .cvr-create-new {
          .button-delete-rule {
               margin-top: 0; 
               padding-top: 25px;
               align-items: flex-start; 
          }
          .sever-side-triggers {
               &-event {
                    .checked-relationship {
                         margin-left: 20px;
                         .custom-control-label {
                              line-height: 17px;
                         }
                         .tooltip-wrapper {
                              position: relative;
                              > svg {
                                   path {
                                        fill: #20a8d8 !important;
                                   }
                              }
                              .block-tooltip {
                                   top: 40%;
                                   left: 200%;
                              }
                         }
                    }
                    .trigger-type,
                    .select-event-sever-side {
                         max-width: 220px;
                         min-width: 0;
                         width: 100%;
                         margin-left: 15px;
                         &.exactly-match {
                              max-width: 250px;
                         }
                         .datepicker-wrapper {
                              .calendar-wrapper {
                                   position: absolute;
                                   z-index: 100;
                              }
                              ~ .invalid-feedback {
                                   display: block;
                              }
                         }
                    }
               }

               .spacing-mb {
                    margin-bottom: 16px;
               }

               .date-range-overlay {
                    position: fixed;
                    height: 100%;
                    width: 100%;
                    left: 0;
                    top: 0;
               }

               .card-body-rules {
                    .conditions-group {
                         .conditions-match {
                              .select-type {
                                   &.date-time {
                                        min-width: 280px;
                                   }

                                   &.filter-date-time {
                                        min-width: 150px;
                                   }

                                   .invalid-feedback {
                                        display: block;
                                   }
                              }
                         }
                    }
               }

               .conditions-match [aria-disabled="true"] {
                    border-radius: 12px;
               }
               .conditions-group {
                    .conditions-match {
                         .select-type {
                              min-width: 235px;
                         }
                    }
               }
               .text-filter {
                    label {
                         color: #18222b !important;
                         font-family: Roboto;
                         font-size: 16px;
                         font-weight: 400;
                         line-height: 22px;
                         padding: 10px 0;
                    }
               }
          }

          .sfpreview {
               .list-results {
                    &::-webkit-scrollbar {
                         width: 3px;
                    }

                    &::-webkit-scrollbar-track {
                         width: 3px;
                         background-color: #e2e6e9;
                         border-radius: 2px;
                         margin-top: 5px;
                         margin-bottom: 5px;
                    }

                    &::-webkit-scrollbar-thumb {
                         background: #73818f;
                         width: 3px;
                         border-radius: 12px;
                         border: none;
                    }

                    ul {
                         margin-bottom: 0;
                         margin-left: -21px;
                    }
               }

               .row-scroll .result-group +.result-group {
                    padding-top: 8px;
               }
          }
     }
}

.rule-detail,
.input-value-rules {
     .preview-value {
          text-decoration: underline;
          cursor: pointer;
     }
}

.accordion {
     .accordion-item {
          &.accordion-item-rule {
               .collapse {
                    .option-item {
                         &.variable-trigger-conversion {
                              .block-tooltip {
                                   top: 0px;
                                   padding: 0;

                                   svg {
                                        top: -6px;
                                   }

                                   &.lookup-rule-conversion {
                                        top: 2px;
                                        left: 77%;
                                        transform: translate(-50%, -100%);
                                        min-width: inherit;
                                        background-color: #18222b;
                                        border-radius: 12px;
                                   }

                                   &.lookup-rule-trigger {
                                        top: -6px;

                                        svg {
                                             top: -8px;
                                        }
                                   }

                                   .block-tooltip-text {
                                        p {
                                             margin-bottom: 0;
                                             font-size: 15px;
                                             font-weight: 400;
                                             line-height: 20px;
                                             letter-spacing: 0.08px;
                                             word-break: break-word;
                                        }

                                        button {
                                             line-height: 13px;
                                             padding: 0.125rem 0.4375rem;
                                             background-color: #fff;
                                             color: #20a8d8;
                                        }
                                   }
                              }
                         }
                    }
               }

               .option-item {
                    &.variable-trigger-conversion {
                         img {
                              margin-right: -4px;
                         }
                    }

                    span {
                         color: #73818f;
                         font-family: Roboto;
                         font-size: 11px;
                         font-weight: 400;
                         line-height: 13px;
                         letter-spacing: 0.15714286px;
                    }
               }
          }

          .default-rules {
               .row-rule {
                    .option-item {
                         &.variable-trigger-conversion {
                              &:last-child {
                                   &::after {
                                        content: none;
                                   }
                              }
                         }
                    }
               }
          }

          .row-rule {
               .option-item {
                    &.variable-trigger-conversion {
                         .block-tooltip {
                              top: 0px;
                              padding: 0;

                              svg {
                                   top: -6px;
                                   transform: rotate(-90deg) scaleY(-1);
                              }

                              .block-tooltip-text {
                                   p {
                                        margin-bottom: 0;
                                        font-size: 15px;
                                        font-weight: 400;
                                        line-height: 20px;
                                        letter-spacing: 0.08px;
                                        word-break: break-word;
                                   }

                                   button {
                                        line-height: 13px;
                                        padding: 0.125rem 0.4375rem;
                                        background-color: #fff;
                                        color: #20a8d8;
                                   }
                              }
                         }
                    }
               }
          }

          .option-custom {
               &.variable-trigger-conversion {
                    .icon-trigger-lookup,
                    .icon-trigger {
                         position: relative;
                         top: 2px;
                    }
               }
          }
     }
}

.accordion .accordion-item.accordion-item-rule {
     .row-rule-trigger-accordion {
          .datalayer-variables {
               padding: 5px 20px 5px 0px;
               max-width: 734.5px;

               .content {
                    max-width: none;
               }

               .rule-trigger-conversion {
                    padding-right: 0;

                    .variable-trigger-conversion {
                         img {
                              margin-right: 12px;
                         }
                    }

                    .option-item {
                         position: relative;

                         .block-tooltip {
                              top: -7px;
                              left: 50%;
                              transform: translate(-50%, -100%);
                              min-width: fit-content;
                              background-color: #18222b;
                              border-radius: 12px;

                              .arrow-tooltip-preview {
                                   width: 42px;
                                   top: auto;
                                   bottom: 7px;
                                   left: 50%;
                                   transform: translate(-50%, 100%) rotate(180deg);
                                   color: #18222b;
                              }

                              .block-tooltip-text {
                                   padding: 6px 8px;
                                   text-align: center;
                                   white-space: nowrap;
                                   border-radius: 5px;

                                   p {
                                        font-size: 10px;
                                        font-weight: 500;
                                        line-height: 12px;
                                        letter-spacing: 0.16666667px;
                                        color: #fff;
                                   }
                              }
                         }
                    }

                    .variable-trigger-conversion {
                         margin-right: 12px;
                    }
               }
          }
     }
}

.row-rule-trigger-accordion {
     .datalayer-variables {
          &.rule-trigger-conversion {
               .variable-path {
                    .variable-path-text {
                         top: -80px;
                         // left: calc(-80% - 15px);
                         transform: none;
                         left: auto;
                         right: 45px;

                         .code-preview {
                              max-width: 500px;
                         }
                    }
               }
          }

          .content {
               max-width: none;
          }
     }
}

.datalayer-variables {
     &.rule-trigger-conversion {
          .variable-path {
               .variable-path-text {
                    transform: translateX(-87%);
                    top: -73px;
                    left: calc(-64% - 15px);

                    .code-preview {
                         padding: 17px 17px;

                         svg {
                              left: 99%;
                              bottom: 10px;
                              top: auto;
                              transform: rotateZ(180deg);
                              height: 30px !important;
                         }
                    }

                    .card-body {
                         padding: 0;
                         margin-bottom: 5px;

                         p {
                              line-height: 17px;
                         }
                    }
               }
          }

          .option-item {
               span {
                    color: #73818f;
                    font-family: Roboto;
                    font-size: 11px;
                    font-weight: 400;
                    line-height: 13px;
                    letter-spacing: 0.15714286px;
               }
          }
     }
}

.subscriber .cvr-create-new-dataAction .content-c {
     &.fields-options {
          height: auto;

          .card {
               box-shadow: none !important;
          }
     }

     &.parameter-field {
          height: auto;

          .input-custom {
               margin-top: -29px;

               .insert-variable-dropdown-icon {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
               }
          }

          .browser-step4-form {
               margin-bottom: 7px;
          }

          .custom-row {
               margin-top: 35px;
          }
     }
}

.template-contents {
     .label-id-input {
          margin-top: 25px;
     }

     .browser-step4-form .insert-variable-dropdown-icon {
          justify-content: flex-end !important;
     }

     .browser-step4-form {
          margin-bottom: 6px;
     }

     &-header {
          label {
               color: red;
          }
     }

     .template-field-row {
          gap: 15px;
          align-items: flex-end;
          justify-content: flex-start;
          padding: 0 15px;
     }
     a {
          &:hover {
               text-decoration: underline !important;
          }
     }
}

.template-data-action {
     .form-group {
          label {
               line-height: inherit;
          }

          &.fields-options {
               margin-bottom: 0;

               .card {
                    width: -webkit-fill-available;

                    label:not(.add-row)::after {
                         content: "";
                         position: absolute;
                         top: 7px;
                         right: -14px;
                         width: 7px;
                         height: 7px;
                         transform: translateY(-50%) rotate(45deg);
                         border: 2px solid #000;
                         border-top: none;
                         border-left: none;
                         transition: transform 0.3s;
                    }

                    label:not(.add-row) {
                         &.show {
                              &::after {
                                   top: 10px;
                                   transform: translateY(-50%) rotate(225deg);
                              }
                         }
                    }

                    &.no-options-info {
                         width: 320px;
                    }
               }
          }
     }

     .field-input-variable {
          .input-icons {
               .insert-variable-dropdown {
                    top: 45px;
               }
          }
     }

     .collapse-text {
          label,
          label::after {
               cursor: pointer;
          }
     }

     label {
          line-height: inherit;
          position: relative;

          &.collase-data-action::after {
               content: "";
               position: absolute;
               top: 7px;
               right: -14px;
               width: 7px;
               height: 7px;
               transform: translateY(-50%) rotate(45deg);
               border: 2px solid #000;
               border-top: none;
               border-left: none;
               transition: transform 0.3s;
          }

          &.collase-data-action {
               &.show {
                    &::after {
                         top: 10px;
                         transform: translateY(-50%) rotate(225deg);
                    }
               }
          }

          &.advanced-collapse {
               cursor: pointer;
               font-weight: 500;
               color: #212c36;
               margin-top: 10px;
          }

          &.template-content-header {
               color: #212c36;
               font-family: Roboto;
               font-size: 14px;
               font-weight: 500;
          }
     }

     .custom-control-input {
          z-index: 10;
     }

     .form-control {
          min-height: 37px;
     }

     .parameter-field {
          height: auto;

          &.parameter-field-event {
               width: 100%;
               margin-left: -9px;
               margin-right: -9px;

               .icon-delete {
                    margin-left: -4px;
               }

               .select-wrapper {
                    .form-control {
                         &.is-invalid {
                              background-position-x: calc(100% - 12px);
                              background-position-y: center;
                         }
                    }
               }

               div[class*="col"] {
                    padding-left: 7.5px;
                    padding-right: 7.5px;
               }
          }

          &.parameter-field-event-facebook {
               .field-parameter-button {
                    margin-top: 0;
               }
          }

          .select-react-customize .select-wrapper > input {
               &.is-invalid {
                    border: 1px solid #f86c6b !important;
               }
          }

          .invalid-feedback {
               display: block;
          }

          .button-group {
               width: max-content;
          }

          .insert-variable-dropdown {
               top: 45px;
          }
     }

     .select-event-name {
          .form-control.is-invalid ~ .invalid-feedback {
               position: absolute;
               margin-left: 8px;
          }

          .event-name-label {
               width: max-content;
          }
     }

     .include-data-tooltip {
          padding-top: 15px;

          .custom-control-label {
               margin-bottom: 0;
          }

          .tooltip-wrapper {
               position: relative;
          }
     }

     // .block-tooltip .block-tooltip-text {
     //      border-top-left-radius: 0;
     // }

     .form-group {
          &.select-event-name {
               margin-left: -9px;
               margin-right: -9px;

               .mw-250 {
                    max-width: 250px;
               }

               .mw-350 {
                    max-width: 350px;
               }

               div[class*="col"] {
                    padding-left: 7.5px;
                    padding-right: 7.5px;
               }
          }
     }

     &.microsoft-ads {
          .parameter-field {
               .select-event {
                    padding-left: 7.5px;
                    padding-right: 7.5px;

                    .form-control {
                         max-width: 178px;
                    }
               }

               .event-value {
                    padding-left: 7.5px;
                    padding-right: 7.5px;
                    max-width: 376px;
                    width: 40%;

                    .form-control {
                         text-overflow: ellipsis;
                    }

                    li {
                         .form-control {
                              max-width: none;
                              min-width: auto;
                         }
                    }
               }
          }
     }
}

.subscriber .cvr-create-new.cvr-create-new-dataAction {
     .template-data-action {
          .insert-variable-dropdown-icon {
               justify-content: flex-start;
          }
     }

     .cvr-step-card {
          border-radius: 12px;

          .microsoft-ads {
               .form-group.border-bottom .button-group {
                    position: relative;
                    top: 0;
                    right: 0;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: max-content;

                    &.input-custom-param {
                         position: relative;
                         margin-top: 35px;
                    }
               }

               .content-c.parameter-field {
                    // .select-field-parameters {
                    //      display: flex;
                    //      align-items: flex-end;
                    //      height: 100%;
                    // }

                    .input-custom {
                         margin-top: 0;
                    }
               }
          }
     }
}

.microsoft-ads {
     .parameter-field {
          .field-parameter-button {
               margin-top: 0;
          }
     }
}

.parameter-field,
.template-conversion {
     .field-parameter {
          display: flex;
          align-items: center;
          width: 376px;
          height: 37px;
          border-radius: 12px;
          border: 1px solid #e4e7e9;
          margin-right: 20px;
          margin-bottom: 10px;
          padding-left: 0;
          padding-right: 0;

          &-invalid {
               margin-bottom: 20px;
          }

          .field-parameter-child {
               .select-react-customize {
                    .select-wrapper {
                         .form-control {
                              border: none !important;

                              &.is-invalid {
                                   background-position-x: calc(100% - 12px);
                                   background-position-y: center;
                              }
                         }
                    }
               }

               .invalid-feedback {
                    position: absolute;
               }

               &:first-child {
                    .select-react-customize {
                         .select-wrapper {
                              .form-control {
                                   border-right: 1px solid #e4e7e9 !important;
                                   border-radius: 0;
                              }
                         }
                    }
               }
          }
     }

     .invalid-feedback {
          margin-bottom: 10px;
     }

     .field-parameter-custom {
          display: flex;
          align-items: center;
          max-width: 376px;
          height: 37px;
          margin-right: 20px;
     }

     .field-parameter-button {
          display: flex;
          align-items: center;
          height: 37px;

          .icon:not(.icon-c-s):not(.icon-custom-size) {
               height: 30px;
               width: 30px;

               &.add-field-icon {
                    height: 21px;
                    width: 21px;
               }
          }

          &.input-custom-param {
               position: relative;
               margin-top: 35px;
          }

          &.user-properties {
               top: 33px;
          }
     }

     .template-conversion-body {
          .field-parameter {
               border: none;
          }
     }

     .parameter-field {
          &-row {
               margin-bottom: 10px;
          }

          &-invalid {
               margin-bottom: 5px;

               div.input-icons.input-icons-errors {
                    margin-bottom: 0;
               }
          }
     }

     // .block-tooltip .block-tooltip-text {
     //      border-top-left-radius: 0;
     // }

     .field-event-id {
          &:not(.invalid-conversion) {
               .input-content.is-invalid {
                    margin-top: 5px;
                    margin-bottom: 5px;

                    & + .invalid-feedback {
                         position: absolute;
                         margin-top: -5px;
                    }
               }
          }
     }
}

.template-conversion {
     .template-conversion-body {
          .field-parameter-button {
               margin-top: 0;
          }
     }

     .conversion-measurementID {
          .invalid-feedback {
               position: absolute;
               white-space: nowrap;
          }
     }
}

.subscriber {
     .cvr-step-2.cvr-create-new-dataAction {
          .react-select__control {
               cursor: pointer;
          }
     }
}

@media screen and (min-width: 1350px) {
     .subscriber {
          .cvr-create-new {
               &.cvr-create-new-dataAction {
                    .cvr-step-card {
                         .form-group {
                              &.border-bottom {
                                   position: relative;

                                   .button-group {
                                        &.input-custom-param {
                                             position: relative;
                                             height: 37px;
                                             align-items: center;
                                             justify-content: start;
                                             top: auto;
                                             right: 0;
                                             width: 100%;
                                        }
                                   }
                              }
                         }
                    }
               }
          }
     }
}

.confirm-save-popup {
     &.popup-trigger-preview {
          .confirm-save {
             .tab-content {
               p {
                    margin-left: -17px;
                    margin-right: -17px;
               }
             }
          }
          .modal-dialog {
               max-width: 700px;
          }

          .popup-loading {
               position: relative;
               margin-top: 40px;
          }
          .popup-actions {
               margin-top: 20px;
          }
          .tab-header {
               display: flex;
               justify-content: center;
               margin-bottom: 20px;

               .tab-button {
                    padding: 10px 20px;
                    margin: 0 10px;
                    border: 1px solid #f9f9f9;
                    background-color: #f9f9f9;
                    cursor: pointer;
                    // transition: background-color 0.1s;
                    background: #f0f3f5;
                    font-size: 15px;
                    border-radius: 10px;
                    font-weight: 500;

                    &.active {
                         background: #20a8d8;
                         color: #fff;
                         border-color: #20a8d8;
                    }
               }

               .tab-content {
                    margin: 20px 0;
                    text-align: center;
               }
          }

          .table-container {
               display: flex;
               flex-direction: column;
               width: 100%;
               max-width: 600px;
               border: 1px solid #ccc;
               border-radius: 5px;
               overflow: hidden;
          }

          .table-header-1 {
               display: flex;
               background-color: #f4f4f4;
               font-weight: bold;
               padding: 10px 0;
               position: sticky; /* Sticky header */
               top: 0;
               z-index: 1;
               box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
          }

          .header-cell {
               flex: 1;
               text-align: left;
               padding: 15px;
               text-align: center;
               // border-right: 1px solid #ccc;
          }

          .header-cell:last-child {
               border-right: none;
          }

          .table-body {
               max-height: 300px;
               overflow-y: auto; /* Scrollable body */
               &::-webkit-scrollbar {
                    width: 2px;
                    height: 7px;
                    position: relative;
                    top: 10px;
               }
               &::-webkit-scrollbar-track {
                    background-color: rgb(226, 225, 225);
                    border-bottom-left-radius: 9px;
                    border-bottom-right-radius: 9px;
               }
               &::-webkit-scrollbar-thumb {
                    background-color: #bdbcbc;
                    // border-bottom-left-radius: 9px;
                    // border-bottom-right-radius: 9px;
                    border-radius: 15px;
               }
          }

          .table-row {
               display: flex;
               // padding: 10px 0;
               border-bottom: 1px solid #ddd;
          }

          .row-cell {
               flex: 1;
               text-align: left;
               padding: 10px;
               text-align: center;
               // border-right: 1px solid #ccc;
               &.row-property {
                    // text-align: left;
                    padding-left: 20px;
                    width: 280px;
               }

          }

          .row-cell:last-child {
               border-right: none;
          }

          .table-row:nth-child(odd) {
               background-color: #f9f9f9;
          }
     }
}
